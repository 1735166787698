import React from 'react';
import SEO from '../components/SEO';

export default function FourOhFourPage() {
  return (
    <>
      <SEO title={'Page not found'} />
      <div>
        <p>That page doesn't exist</p>;
      </div>
    </>
  );
}
